"use client";

import { useAuth, useUser } from "@clerk/nextjs";
import { useEffect } from "react";

const RevokeOthers = () => {
  const { sessionId } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (sessionId && user) {
      const revokeAll = async () => {
        const sessions = await user.getSessions();
        sessions.forEach((session) => {
          // Revoke all sessions except this one
          if (session.id !== sessionId) {
            session.revoke();
          }
        });
      };
      revokeAll();
    }
  }, [sessionId, user]);

  return <></>;
};

export default RevokeOthers;
